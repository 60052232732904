.Login {
  font-family: sans-serif;
  text-align: center;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  margin: -20px 0 50px;
}
  
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

* {
  box-sizing: border-box;
}
  
.Login h1 {
  font-weight: bold;
  margin: 0;
}
  
.Login h2 {
  text-align: center;
}
  
.Login p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
  
.Login span {
  font-size: 12px;
}
  
.Login a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}
  
.Login button {
  border-radius: 20px;
  border: 1px solid #fe8800;
  background-color: #fe8800;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.Login button:active {
  transform: scale(0.95);
}
  
.Login button:focus {
  outline: none;
}
  
.Login button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}
  
.Login form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}
  
.Login input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
  
.Login .container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.Login .form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}
  
.Login .sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}
  
  /* .container.right-panel-active .sign-in-container {
    transform: translateX(100%);
  }
  
  .sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
  }
  
  .container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  } */
  
  @keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }
  
    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }
  
.Login .overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}
  
  /* .Login .container.right-panel-active .overlay-container {
    transform: translateX(-100%);
  } */
  
.Login .overlay {
  background: #fe8800;
  background: -webkit-linear-gradient(to right, #056839, #fe8800);
  background: linear-gradient(to right, #056839, #fe8800);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}
  
  /* .container.right-panel-active .overlay {
    transform: translateX(50%);
  } */
  
.Login .overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.Login .overlay-left {
  transform: translateX(-20%);
}

.Login .container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.Login .overlay-right {
  text-align: justify;
  right: 0;
  transform: translateX(0);
}

.Login .container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

  